import httpService from '../../../helpers/http.service'

export default {
  get (path, params) {
    return httpService.get(`${path}`, {params})
  },
  save (path, payload, id) {
    return  !id ? httpService.post(`${path}`, payload): httpService.put(`${path}/${id}`, payload)
  },
  delete(path, id) {
    return  httpService.delete(`${path}/${id}`);
  },
  getCatalog(event, route, field, params = {}) {
    return new Promise(( resolve, reject) => {
      let data;
      this.get(route, params).then(res =>  {
        let result = res.data;
        result = result.data ? result.data: result;
        if (!event.query.trim().length) {
          data = [...result];
        } else {
          data = result.filter((x) => {
            return x[field].toLowerCase().includes(event.query.toLowerCase());
          });
        }
         resolve(data);
      }).catch(() => reject([]))
    });
  },
  abilities(id) {
    return  httpService.post(`positions/${id}/abilities`);
  },
  assignmentAbilities(id,params) {
    return  httpService.post(`positions/${id}/abilities/assignment`,params);
  },
}
